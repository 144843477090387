import React, { useEffect } from 'react';
import FoodGrocery from '../../Components/Home/FoodGrocery';
import WhatWeFeature from '../../Components/Home/WhatWeFeature';
import LookOurNumbers from '../../Components/Home/LookOurNumbers';
import SliderImg from '../../Components/Services/SliderImg';
import Customers from '../../Components/Home/Customers';
import BestService from '../../Components/Home/BestService';
import HomeBanner from '../../Components/Home/HomeBanner';
import Subscribe from '../../Components/Home/Subscribe';
import 'aos/dist/aos.css';  
import AOS from 'aos';
const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <React.Fragment>
      <HomeBanner/>
      <FoodGrocery />
      <WhatWeFeature/>
      <LookOurNumbers/>
      <BestService/>
      <SliderImg/>
      <Customers/>
      <Subscribe/>
    </React.Fragment>
  )
}

export default Home