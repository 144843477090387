

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const VisionMission = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row mt-8 mx-auto gap-6 md:gap-10 max-w-screen-xl px-6 md:px-16">
        <div 
          className="bg-gray-50 p-6 md:p-10 rounded-lg shadow-lg text-center w-full lg:w-1/2 space-y-4 md:space-y-6"
          data-aos="fade-up" 
        >
          <h2 className="text-xl md:text-3xl font-semibold mb-2 text-[#2D8014]">PLACE ORDER</h2>
          <hr className="border-[#2D8014] border-t-2 w-[70%] mx-auto" />
          <hr className="border-[#2D8014] border-t-2 mt-3 w-[70%] mx-auto" />
          <p className="text-gray-600 tracking-wide text-lg md:text-xl">
            Place your order effortlessly with our user-friendly system, ensuring a quick, convenient process that gets your products delivered right to your door.
          </p>
        </div>
        <div 
          className="bg-gray-50 p-6 md:p-10 rounded-lg shadow-lg text-center w-full lg:w-1/2 space-y-4 md:space-y-6"
          data-aos="fade-up" 
        >
          <h2 className="text-xl md:text-3xl font-semibold mb-2 text-[#2D8014]">PAYMENT</h2>
          <hr className="border-[#2D8014] border-t-2 w-[70%] mx-auto" />
          <hr className="border-[#2D8014] border-t-2 mt-3 w-[70%] mx-auto" />
          <p className="text-gray-600 tracking-wide text-lg md:text-xl">
            Make secure payments through our platform with various options, ensuring a fast, seamless, and reliable transaction experience for your convenience and peace of mind.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VisionMission;
