import React, { useEffect } from 'react';
import SendUsMessage from '../../Components/Contact/SendUsMessage';

import SimpleBanner from '../Common/SimpleBanner'
import Contactbanar from '../../Assets/common/banar.jpeg'
import Map from '../../Components/Contact/Map';
import 'aos/dist/aos.css';  
import AOS from 'aos';
const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <React.Fragment>
       <SimpleBanner
        image={Contactbanar}
        name="Contact"
        path="Home / Contact"
      />
      
      <SendUsMessage/>
      <Map/>
    </React.Fragment>
  )
}
export default Contact