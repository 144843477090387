

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import img1 from '../../Assets/Aboutus/Group.png';
import img2 from '../../Assets/Aboutus/paystore.png';

const BestService = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    
    return (
        <React.Fragment>
            <div className='px-4 md:px-16 flex flex-col md:flex-row mt-5 gap-8'>
                <div className='w-full md:w-[50%] space-y-5' data-aos="fade-right">
                    <h3 className='text-[#2D8014] text-xl md:text-2xl'>Mobile Apps</h3>
                    <h1 className='text-3xl md:text-5xl'>Best Service to fulfill your expectations.</h1>
                    <p className='text-sm md:text-base lg:text-lg'>
                    Our Best Service is dedicated to exceeding your expectations with unparalleled quality, attentive support, and reliable performance. We tailor our solutions to meet your unique needs, ensuring your satisfaction and delivering exceptional results every time.  
                    </p>
                    <h1 className='text-lg md:text-xl'>Download Our Mobile App</h1>
                    <img src={img2} alt='Download App' className='h-12 md:h-16' />
                </div>
                <div className='w-full md:w-[50%]' data-aos="fade-left">
                    <img src={img1} alt='Service' className='w-full' />
                </div>
            </div>
        </React.Fragment>
    );
};

export default BestService;
