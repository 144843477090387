import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import FasterDelivery from '../../Assets/Home/Faster Delivery.png';
import support from '../../Assets/Home/247support.png';
import EasyToUse from '../../Assets/Home/EasyToUse.png';

const WhatWeFeature = () => {
  
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  return (
    <React.Fragment>
      <h1 className='text-4xl md:text-5xl text-center mt-10' data-aos="fade-up">What We Feature</h1>
      <p className='text-lg md:text-2xl text-center mt-5' data-aos="fade-up">
        Highlights our top services and offerings, showcasing quality, innovation, <br className='hidden md:block' />and customer satisfaction to meet your needs.
      </p>

      <div className='px-4 md:px-16 flex flex-col md:flex-row gap-10 items-center justify-center mt-10'>
        <div className='text-center' data-aos="fade-right">
          <img src={FasterDelivery} alt='FasterDelivery' className='w-[70%] md:w-[85%] mx-auto'/>
          <h1 className='text-2xl md:text-3xl text-[#2D8014] mt-3'>Faster Delivery</h1>
          <p className='text-lg md:text-xl'>Get your products swiftly with our efficient, rapid delivery service, saving you time.</p>
        </div>
        
        <div className='text-center' data-aos="fade-up">
          <img src={EasyToUse} alt='EasyToUse' className='w-[75%] md:w-[95%] mx-auto'/>
          <h1 className='text-2xl md:text-3xl text-[#2D8014] mt-3'>Easy To Use</h1>
          <p className='text-lg md:text-xl'>Enjoy a user-friendly experience with simple, intuitive interfaces designed for everyone.</p>
        </div>
        
        <div className='text-center' data-aos="fade-left">
          <img src={support} alt='support' className='w-[70%] md:w-[85%] mx-auto'/>
          <h1 className='text-2xl md:text-3xl text-[#2D8014] mt-3'>24/7 Support</h1>
          <p className='text-lg md:text-xl'>Access round-the-clock customer support, always available to assist you anytime, day or night.</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhatWeFeature;
