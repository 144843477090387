import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SimpleBanner from '../Common/SimpleBanner';
import Aboutbanar from '../../Assets/common/banar.jpeg';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <SimpleBanner
        image={Aboutbanar}
        name="Privacy & Pricing Policy"
        path="Home / Privacy & Pricing Policy"
      />
    <div className="bg-gray-50 py-12">
      <div className="container mx-auto px-4 md:px-8 lg:px-16">
        <div className="bg-white shadow-md rounded-lg p-6 lg:p-10">
          <div className="space-y-8 text-gray-700">

            {/* Privacy Policy Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-4 text-gray-900">Privacy Policy</h2>

              <p className="mb-4">
                Your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website and services.
              </p>

              <h3 className="text-xl font-semibold mb-3">1. Information Collection</h3>
              <p className="mb-4">
                We collect personal information that you provide during account registration, order placement, and customer support interactions. This includes, but is not limited to, your name, email, phone number, delivery address, and payment information.
              </p>

              <h3 className="text-xl font-semibold mb-3">2. Use of Information</h3>
              <p className="mb-4">
                The information collected is used to process your orders, provide customer support, and enhance your shopping experience. We may also send promotional updates if you’ve opted in to receive them.
              </p>

              <h3 className="text-xl font-semibold mb-3">3. Data Protection & Security</h3>
              <p className="mb-4">
                We take the security of your personal data seriously. All sensitive information, such as payment details, is encrypted and securely processed through our trusted payment gateway partners, including Razorpay. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute protection.
              </p>

              <h3 className="text-xl font-semibold mb-3">4. Sharing of Information</h3>
              <p className="mb-4">
                We do not sell, trade, or share your personal information with third parties, except for the purpose of operating our services (e.g., delivery, payment processing). We may also disclose information if required by law or to protect our legal rights.
              </p>

              <h3 className="text-xl font-semibold mb-3">5. Cookies & Tracking</h3>
              <p className="mb-4">
                We use cookies to improve your browsing experience and monitor website traffic. You can control how cookies are used through your browser settings.
              </p>

              <h3 className="text-xl font-semibold mb-3">6. Your Rights</h3>
              <p className="mb-4">
                You have the right to access, update, or request deletion of your personal information at any time. To exercise these rights, please contact us at [your support email].
              </p>
            </section>

            {/* Pricing Policy Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-4 text-gray-900">Pricing Policy</h2>

              <p className="mb-4">
                Our Pricing Policy explains how we determine the prices of our products and services, including any applicable taxes, delivery charges, and payment terms.
              </p>

              <h3 className="text-xl font-semibold mb-3">1. Product Pricing</h3>
              <p className="mb-4">
                All prices listed on our website are in Indian Rupees (INR) and include applicable taxes. We reserve the right to change prices at any time, but these changes will not affect orders that have already been placed and confirmed.
              </p>

              <h3 className="text-xl font-semibold mb-3">2. Discounts & Promotions</h3>
              <p className="mb-4">
                Any discounts or promotional offers are subject to specific terms and conditions. Unless otherwise stated, discounts cannot be combined with other offers.
              </p>

              <h3 className="text-xl font-semibold mb-3">3. Delivery Charges</h3>
              <p className="mb-4">
                Delivery charges are calculated based on your location and the total value of your order. These charges will be displayed at checkout and included in the final amount.
              </p>

              <h3 className="text-xl font-semibold mb-3">4. Payment Methods</h3>
              <p className="mb-4">
                We accept a variety of payment methods, including credit/debit cards, UPI, and net banking. Payments are securely processed via Razorpay, our trusted payment gateway partner.
              </p>

              <h3 className="text-xl font-semibold mb-3">5. Payment Security</h3>
              <p className="mb-4">
                We use Razorpay’s PCI-DSS compliant payment system to ensure the highest level of payment security. Your payment information is never stored on our servers, and all transactions are encrypted to prevent unauthorized access.
              </p>

              <h3 className="text-xl font-semibold mb-3">6. Refunds & Cancellations</h3>
              <p>
                Please refer to our <span onClick={() => { navigate('/refund-cancellation'); window.scrollTo(300, 0); }}
                  className="underline text-blue-600 cursor-pointer">
                  Refund & Cancellation Policy
                </span> for detailed information regarding refunds and cancellations.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;