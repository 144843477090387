import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import logo from '../../Assets/logos/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
const BottomNav = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get current location
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const getLinkClass = (path) => {
        return location.pathname === path 
            ? "text-base font-semibold text-[#2D8014] cursor-pointer tracking-wide"
            : "text-base font-semibold hover:text-[#2D8014] cursor-pointer tracking-wide";
    };

    return (
        <>
            {/* Navbar */}
            <nav className="bg-[#fffefc] text-[#26232A] px-6 md:px-16 py-2 shadow-2xl">
                <div className="container mx-auto flex items-center justify-between">
                    {/* 1st Section - Logo */}
                    <div>
                        <img
                            src={logo}
                            alt="baisaly-logo"
                            className='w-auto h-14'
                        />
                    </div>

                    {/* Middle Section - Page Links (hidden on small screens) */}
                    <div className="hidden md:flex space-x-8">
                        <div
                            onClick={() => navigate('/')}
                            className={getLinkClass('/')}
                        >
                            Home
                        </div>
                        <div
                            onClick={() => navigate('/about')}
                            className={getLinkClass('/about')}
                        >
                            About
                        </div>
                        <div
                            onClick={() => navigate('/services')}
                            className={getLinkClass('/services')}
                        >
                            Services
                        </div>
                        <div
                            onClick={() => navigate('/contact')}
                            className={getLinkClass('/contact')}
                        >
                            Contact
                        </div>
                    </div>

                    {/* 3rd Section - Login Button */}
                    <div className="hidden md:block">
                        <button
                            className="tracking-wide bg-[#2D8014] text-white font-bold py-2 px-4 rounded">
                            Log In
                        </button>
                    </div>

                    {/* Hamburger Menu for Small Screens */}
                    <div className="md:hidden">
                        <FaBars
                            onClick={toggleSidebar}
                            size={24}
                            className="cursor-pointer" />
                    </div>
                </div>
            </nav>

            {/* Sidebar for Small Screens */}
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
                    <div className="fixed top-0 left-0 w-64 h-full bg-white shadow-md">
                        <div className="p-4">
                            <button className="text-black text-lg font-bold mb-8 float-right"
                                onClick={toggleSidebar}>
                               <IoClose size={25} className='text-green-500'/>
                            </button>

                            <ul className="space-y-4 text-lg">
                                <li
                                    onClick={() =>{ navigate('/')
                                        toggleSidebar();
                                    }}
                                    className={getLinkClass('/')}
                                    
                                >
                                    Home
                                </li>
                                <li
                                    onClick={() => {
                                        navigate('/about');
                                        toggleSidebar();
                                    }}
                                    className={getLinkClass('/about')}
                                >
                                    About
                                </li>
                                <li
                                    onClick={() => {
                                        navigate('/services');
                                        toggleSidebar();
                                    }}
                                    className={getLinkClass('/services')}
                                >
                                    Services
                                </li>
                                <li
                                    onClick={() => {
                                        navigate('/contact');
                                        toggleSidebar();
                                    }}
                                    className={getLinkClass('/contact')}
                                >
                                    Contact
                                </li>
                            </ul>
                            <button
                                className="tracking-wide mt-8 bg-[#2D8014] text-white font-bold py-2 px-4 rounded">
                                Log In
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomNav;














// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const BottomNav = () => {
//     const navigate = useNavigate();

//     return (
//         <React.Fragment>
//             <nav className="bg-gray-800 text-white">
//                 <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//                     <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
//                         <div className="flex justify-start lg:w-0 lg:flex-1">
//                             <a href="#">
//                                 <span className="sr-only">Your Company</span>
//                             </a>
//                         </div>
//                         <div className="hidden md:flex space-x-10">
//                             <div
//                                 onClick={() => navigate('/')}
//                                 className="text-base font-medium text-gray-300 hover:text-white">
//                                 Home
//                             </div>
//                             <div
//                                 onClick={() => navigate('/about')}
//                                 className="text-base font-medium text-gray-300 hover:text-white">
//                                 About
//                             </div>
//                             <div
//                                 onClick={() => navigate('/products')}
//                                 className="text-base font-medium text-gray-300 hover:text-white">
//                                 Products
//                             </div>
//                             <div
//                                 onClick={() => navigate('/contact')}
//                                 className="text-base font-medium text-gray-300 hover:text-white">
//                                 Contact
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </nav>
//         </React.Fragment>
//     )
// }

// export default BottomNav