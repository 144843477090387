
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SpecialPrawns from '../../Assets/Home/SpecialPrawns.png';
import EggOmlet from '../../Assets/Home/EggOmlet.png';
import EggNoodles from '../../Assets/Home/EggNoodles.png';
import SweetCorn from '../../Assets/Home/SweetCorn.png';
import Cauliflower from '../../Assets/Home/Cauliflower.png';
import FreshVegetables from '../../Assets/Home/FreshVegetables.png';

const FoodGrocery = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <React.Fragment>
      <h1 className='text-5xl text-center md:mt-12' data-aos="fade-up">Food & Grocery</h1>

      <div className='px-4 md:px-16 flex flex-col md:flex-row mt-6 gap-6 md:mt-12 '>
        <div className='w-full md:w-[50%]' data-aos="fade-right ">
          <div>
            <img src={SpecialPrawns} alt='SpecialPrawns' className='rounded-md w-full' />
          </div>
          <h1 className='text-2xl tracking-wide mt-3'>Ghee & Oil</h1>
          <p className='tracking-wide text-xl mt-3'>"Special prawns, cooked to perfection, offering a rich, savory flavor with a fresh, oceanic taste."</p>
        </div>

        <div className='w-full md:w-[50%] flex flex-col md:flex-row gap-7' data-aos="fade-left">
          <div className='w-full md:w-[50%]'>
            <img src={EggNoodles} alt='EggNoodles' className='w-full rounded-md' />
            <h1 className='text-2xl tracking-wide mt-3'>Coldrinks</h1>
            <p className='tracking-wide text-xl mt-3'>"Egg noodles, cooked al dente, delivering a soft, savory taste with a hint of spices."</p>
          </div>
          <div className='w-full md:w-[50%]'>
            <img src={EggOmlet} alt='EggOmlet' className='w-full rounded-md' />
            <h1 className='text-2xl tracking-wide mt-3'>Masala</h1>
            <p className='tracking-wide text-xl mt-3'>"Fluffy egg omelet, perfectly seasoned, with a light texture and delightful filling for a satisfying meal."</p>
          </div>

        </div>
      </div>

      <div className='px-4 md:px-16 flex flex-col md:flex-row mt-6 gap-6'>
        <div className='w-full md:w-[50%] flex flex-col md:flex-row gap-7' data-aos="fade-right">
        
          <div className='w-full md:w-[50%]'>
            <img src={Cauliflower} alt='Cauliflower' className='w-full rounded-md' />
            <h1 className='text-2xl tracking-wide mt-3'>Veg </h1>
            <p className='tracking-wide text-xl mt-3'>"Cauliflower, tender and lightly seasoned, providing a healthy, versatile addition to any meal."</p>
          </div>
          <div className='w-full md:w-[50%]'>
            <img src={SweetCorn} alt='SweetCorn' className='w-full rounded-md' />
            <h1 className='text-2xl tracking-wide mt-3'>Non-veg</h1>
            <p className='tracking-wide text-xl mt-3'>"Sweet corn, naturally crisp and juicy, with a burst of sweetness in every bite."</p>
          </div>
        </div>

        <div className='w-full md:w-[50%]' data-aos="fade-left">
          <div>
            <img src={FreshVegetables} alt='Fresh Vegetables' className='rounded-md w-full' />
          </div>
          <h1 className='text-2xl tracking-wide mt-3'>Biriyani</h1>
          <p className='tracking-wide text-xl mt-3'>"Fresh vegetables, colorful and nutrient-packed, offering a crisp texture and refreshing taste in every dish."</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FoodGrocery;
