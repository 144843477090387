import React from 'react';
import img1 from '../../Assets/Services/order.png';
import img2 from '../../Assets/Services/payment.png';
import img3 from '../../Assets/Services/sent.png';
import img4 from '../../Assets/Services/Happy.png';

const Howwork = () => {
    return (
        <React.Fragment>
            <h3
                className='text-center text-2xl md:text-3xl mt-10 text-[#2D8014]'
                data-aos="fade-up"
            >
                How does it work
            </h3>
            <h1
                className='text-center text-4xl md:text-6xl mt-5'
                data-aos="fade-up"
                data-aos-delay="200"
            >
                Get served as an emperor.
            </h1>
            <p
                className='text-center mt-5 text-sm md:text-base px-4 md:px-0'
                data-aos="fade-up"
                data-aos-delay="400"
            >
                "Get served as an emperor" promises a royal dining experience, where exceptional service and exquisite cuisine come together. We treat every guest like royalty, ensuring unmatched satisfaction and indulgence in every meal.
            </p>

            <div className="flex flex-col md:flex-row gap-10 px-4 md:px-16 mt-10">
                <div
                    className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]"
                    data-aos="fade-right"
                >
                    <div className="mb-4">
                        <img src={img1} alt="Place Order" className="mx-auto h-16 w-16" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">PLACE ORDER</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Browse our menu and select your favorite dishes. Customize your order and place it online with ease.
                    </p>
                    <hr className="border-[#2D8014] border-t-4 w-[40%] mx-auto rounded-md" />
                </div>

                <div
                    className="bg-gray-50 p-6 md:p-8 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]"
                    data-aos="fade-up"
                    data-aos-delay="200"
                >
                    <div className="mb-4">
                        <img src={img2} alt="Payment" className="mx-auto h-16 w-16" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">PAYMENT</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Complete your order with our secure online payment options. We accept various methods, including cards.
                    </p>
                    <hr className="border-[#2D8014] border-t-4 w-[40%] mx-auto rounded-md" />
                </div>

                <div
                    className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]"
                    data-aos="fade-up"
                    data-aos-delay="400"
                >
                    <div className="mb-4">
                        <img src={img3} alt="Order Sent" className="mx-auto h-16 w-16" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">ORDER SENT</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Your order is swiftly prepared and dispatched. We ensure it arrives fresh and on time at your door.
                    </p>
                    <hr className="border-[#2D8014] border-t-4 w-[40%] mx-auto rounded-md" />
                </div>

                <div
                    className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]"
                    data-aos="fade-left"
                    data-aos-delay="600"
                >
                    <div className="mb-4">
                        <img src={img4} alt="Happy Tummy" className="mx-auto h-16 w-16" />
                    </div>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-[#2D8014]">HAPPY TUMMY</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Enjoy your delicious meal and feel satisfied. Our priority is your happiness and a delightful experience.
                    </p>
                    <hr className="border-[#2D8014] border-t-4 w-[40%] mx-auto rounded-md" />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Howwork;
