


import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import SatisfiedCustomer from '../../Assets/Home/SatisfiedCustomer.png';
import FoodDelivered from '../../Assets/Home/FoodDelivered.png';
import TrustedPartner from '../../Assets/Home/TrustedPartner.png';
import ExperiencedDriver from '../../Assets/Home/ExperiencedDriver.png';
import CountUp from 'react-countup';

const LookOurNumbers = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  return (
    <React.Fragment>
      <h1 className='text-4xl md:text-5xl text-center mt-10' data-aos="fade-up">Look at Our Numbers</h1>

      <div className='flex flex-col md:flex-row px-4 md:px-16 justify-around mt-10 gap-10'>
        <div className='w-full md:w-[25%] flex flex-col items-center justify-center space-y-5' data-aos="fade-right">
          <img src={SatisfiedCustomer} alt='Satisfied Customer' className='w-[50%]' />
          <h1 className='text-4xl md:text-5xl text-[#2D8014] text-center'><CountUp end={10} duration={2} /><span>k</span></h1>
          <p className='text-lg md:text-xl text-center'>Satisfied Customer</p>
        </div>

        <div className='w-full md:w-[25%] flex flex-col items-center justify-center space-y-5' data-aos="fade-up">
          <img src={FoodDelivered} alt='Food Delivered' className='w-[50%]' />
          <h1 className='text-4xl md:text-5xl text-[#2D8014] text-center'><CountUp end={100} duration={2} /><span>k</span></h1>
          <p className='text-lg md:text-xl text-center'>Food Delivered</p>
        </div>

        <div className='w-full md:w-[25%] flex flex-col items-center justify-center space-y-5' data-aos="fade-up">
          <img src={TrustedPartner} alt='Trusted Partner' className='w-[50%]' />
          <h1 className='text-4xl md:text-5xl text-[#2D8014] text-center'><CountUp end={25} duration={2} /><span>k</span></h1>
          <p className='text-lg md:text-xl text-center'>Trusted Partners</p>
        </div>

        <div className='w-full md:w-[25%] flex flex-col items-center justify-center space-y-5' data-aos="fade-left">
          <img src={ExperiencedDriver} alt='Experienced Driver' className='w-[50%]' />
          <h1 className='text-4xl md:text-5xl text-[#2D8014] text-center'><CountUp end={15} duration={2} /><span>k</span></h1>
          <p className='text-lg md:text-xl text-center'>Experienced Drivers</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LookOurNumbers;
