import React from 'react';
import { FaUtensils, FaShoppingCart, FaCarrot, FaRocket } from 'react-icons/fa'; // Importing React Icons

const Howwork = () => {
    return (
        <React.Fragment>
            {/* Heading */}
            <section className="bg-white py-12 px-4 md:px-16">
                <h3 className="text-center text-2xl md:text-3xl text-[#2D8014]" data-aos="fade-up">
                    Our Services
                </h3>
                <h1 className="text-center text-4xl md:text-5xl mt-4" data-aos="fade-up" data-aos-delay="200">
                    Bringing Food & Groceries to Your Doorstep
                </h1>
                <p className="text-center mt-5 text-sm md:text-base px-4 md:px-16 text-gray-600" data-aos="fade-up" data-aos-delay="400">
                    We offer a range of services to ensure that you have the best home delivery experience, whether it's food, groceries, or fresh vegetables, delivered quickly and efficiently.
                </p>
            </section>

            {/* Services Section */}
            <section className="flex flex-col md:flex-row gap-10 px-4 md:px-16 pb-8">
                {/* Service 1 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]" data-aos="fade-right">
                    <FaUtensils className="mx-auto h-20 w-20 text-[#2D8014]" /> {/* Replaced image with icon */}
                    <h2 className="text-2xl md:text-3xl font-semibold text-[#2D8014]">Food Delivery</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Order your favorite meals from local restaurants and enjoy hot, delicious food delivered straight to your home.
                    </p>
                </div>

                {/* Service 2 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]" data-aos="fade-up" data-aos-delay="200">
                    <FaShoppingCart className="mx-auto h-20 w-20 text-[#2D8014]" /> {/* Replaced image with icon */}
                    <h2 className="text-2xl md:text-3xl font-semibold text-[#2D8014]">Grocery Delivery</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Shop for everyday essentials from the comfort of your home. We'll bring groceries right to your doorstep.
                    </p>
                </div>

                {/* Service 3 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]" data-aos="fade-up" data-aos-delay="400">
                    <FaCarrot className="mx-auto h-20 w-20 text-[#2D8014]" /> {/* Replaced image with icon */}
                    <h2 className="text-2xl md:text-3xl font-semibold text-[#2D8014]">Fresh Vegetables</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        Get the freshest vegetables and fruits delivered daily. We guarantee farm-fresh quality at the best prices.
                    </p>
                </div>

                {/* Service 4 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center space-y-5 border-t-4 border-[#2D8014]" data-aos="fade-left" data-aos-delay="600">
                    <FaRocket className="mx-auto h-20 w-20 text-[#2D8014]" /> {/* Replaced image with icon */}
                    <h2 className="text-2xl md:text-3xl font-semibold text-[#2D8014]">Fast Delivery</h2>
                    <p className="text-gray-600 tracking-wide text-sm md:text-base">
                        With our quick and efficient delivery service, you can expect your order to arrive fresh and on time, every time.
                    </p>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Howwork;
