


import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bg from '../../Assets/Contact/bg.png';

const Subscribe = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  return (
    <React.Fragment>
      <h1 className='text-center text-3xl md:text-5xl mt-10' data-aos="fade-up">Subscribe to Our Newsletter</h1>
      <p className='text-center mt-5 px-4 md:px-0' data-aos="fade-up">
        Subscribe to our newsletter and stay updated with the latest news,<br />
        exclusive offers, and insights. Be the first to know about exciting updates and trends!
      </p>
      
      <div className='w-[90%] max-w-[683px] bg-white shadow-lg p-4 py-6 mx-auto mt-8 relative' data-aos="fade-up">
        <input type='text' placeholder='Email Address' className='w-full px-4 py-2 border rounded-md' />
        <button className='px-6 py-3 bg-[#2D8014] text-white rounded-md absolute right-2 top-5 md:right-3 md:top-5'>
          Subscribe
        </button>
      </div>
      
      <img src={bg} alt='bg' className='w-full mt-10' data-aos="fade-up" />
    </React.Fragment>
  )
}

export default Subscribe;
