import React from 'react'

const SimpleBanner = ({ image, name, path }) => {
    return (
        <div
            className='h-[350px] flex items-center md:justify-normal justify-center'
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <div className='md:ml-[10%] mb-[20%]  md:mb-[15%] text-[#2D8014]'>
                <h1 className='text-5xl font-bold tracking-wider capitalize'>{name}</h1>
                <p className='tracking-wider mt-2 capitalize'>{path}</p>
            </div>
        </div>
    )
}

export default SimpleBanner;












// import React from 'react';
// import banner from '../../Assets/common/banner2.png';

// const SimpleBanner = ({ image, name, path }) => {
//     return (
//         <div
//             className="h-[17.7rem] w-full flex items-center justify-center px-4 md:px-16"
//             style={{
//                 backgroundImage: `url(${banner})`,
//                 backgroundSize: "contain", // You can try "contain" if it fits better
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center center", // Ensures image stays centered
//             }}
//         >
//             <div className="flex flex-col items-center text-center text-[#ffffff]">
//                 {/* Page Name and Breadcrumb */}
//                 <h1
//                     style={{ fontFamily: 'cursive' }}
//                     className="text-2xl md:text-4xl font-bold tracking-wider capitalize">
//                     {name}
//                 </h1>
//                 <p className="mt-2 capitalize text-sm md:text-lg tracking-wider">
//                     {path}
//                 </p>
//             </div>
//         </div>
//     );
// }

// export default SimpleBanner;
