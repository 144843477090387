import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";
import About from "../Screens/User/About";
import Contact from "../Screens/User/Contact";
import Blogs from "../Screens/User/Blogs";
import Services from "../Screens/User/Services";
import PrivacyPolicy from "../Screens/User/PrivacyPolicy";
import RefundCancelation from "../Screens/User/RefundCancelation";
import Terms from "../Screens/User/Terms";

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/contact", component: <Contact /> },
  { path: "/services", component: <Services/> },
  { path: "/blogs", component: <Blogs /> },
  { path: "/privacy-pricing", component: <PrivacyPolicy /> },
  { path: "/refund-cancellation", component: <RefundCancelation/> },
  { path: "/terms", component: <Terms /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
