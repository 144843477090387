


import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import coustomber from '../../Assets/Home/coustomber.png';
import coustomber2 from '../../Assets/Home/coustomber2.jpg';
import coustomber3 from '../../Assets/Home/coustomber4.jpg';
import coustomber4 from '../../Assets/Home/coustomber3.jpg';

const Customers = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  return (
    <React.Fragment>
      <h1 className='text-center text-3xl md:text-5xl' data-aos="fade-up">What Customers Say <br /> About Us</h1>
      <p className='text-center mt-5 text-lg md:text-xl px-6' data-aos="fade-up">
        "What Customers Say About Us" reflects genuine feedback, highlighting our commitment to <br />
        excellence, reliable service, and customer satisfaction through testimonials from those who trust and value our solutions.
      </p>

      <div className='flex flex-col gap-10 px-4 md:px-16 mt-10'>
        <div className='flex flex-col md:flex-row gap-10'>
          <div className="p-6 bg-white shadow-lg rounded-lg w-full md:w-[50%]" data-aos="fade-right">
            <div className="flex items-center space-x-4">
              <img className="w-16 h-16 rounded-full" src={coustomber2} alt="Rabindra Kumar" />
              <div>
                <h2 className="text-lg font-semibold">Rabindra Kumar</h2>
                <p className="text-green-600">Customer</p>
              </div>
            </div>
            <p className="mt-4 text-gray-600">
              "The food at seetal was absolutely delicious! The flavors were vibrant, and everything was cooked to perfection..."
            </p>
          </div>

          <div className="p-6 bg-white shadow-lg rounded-lg w-full md:w-[50%]" data-aos="fade-left">
            <div className="flex items-center space-x-4">
              <img className="w-16 h-16 rounded-full" src={coustomber} alt="Shruti Sharma" />
              <div>
                <h2 className="text-lg font-semibold">Shruti Sharma</h2>
                <p className="text-green-600">Customer</p>
              </div>
            </div>
            <p className="mt-4 text-gray-600">
              "I’ve been to seetal multiple times, and it never disappoints. The menu has a great variety..."
            </p>
          </div>
        </div>

        <div className='flex flex-col md:flex-row gap-10'>
          <div className="p-6 bg-white shadow-lg rounded-lg w-full md:w-[50%]" data-aos="fade-right">
            <div className="flex items-center space-x-4">
              <img className="w-16 h-16 rounded-full" src={coustomber3} alt="Chinmaya Sharma" />
              <div>
                <h2 className="text-lg font-semibold">Chinmaya Sharma</h2>
                <p className="text-green-600">Customer</p>
              </div>
            </div>
            <p className="mt-4 text-gray-600">
              "Excellent food with great value! I ordered takeout from [Food Center Name], and the process was smooth and efficient..."
            </p>
          </div>

          <div className="p-6 bg-white shadow-lg rounded-lg w-full md:w-[50%]" data-aos="fade-left">
            <div className="flex items-center space-x-4">
              <img className="w-16 h-16 rounded-full" src={coustomber4} alt="Rahul Chaudhary" />
              <div>
                <h2 className="text-lg font-semibold">Rahul Chaudhary</h2>
                <p className="text-green-600">Customer</p>
              </div>
            </div>
            <p className="mt-4 text-gray-600">
              "I’m so glad I found [Food Center Name]! The meals are bursting with flavor, and the presentation is always beautiful..."
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Customers;
