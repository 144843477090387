import React from 'react';
import SimpleBanner from '../Common/SimpleBanner';
import Aboutbanar from '../../Assets/common/banar.jpeg';

const RefundCancelation = () => {
  return (
    <React.Fragment>
      <SimpleBanner
        image={Aboutbanar}
        name="Refund & Cancellation Policy"
        path="Home / Refund & Cancellation Policy"
      />
    <div className="bg-gray-50 py-12">
      <div className="container mx-auto px-4 md:px-8 lg:px-16">
        <div className="bg-white shadow-md rounded-lg p-6 lg:p-10">
          <div className="space-y-6 text-gray-700">
            
            <section>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">1. Order Cancellations</h2>
              <p>
                Orders can be canceled within 1 hour of placing them, provided that they have not been processed or dispatched. 
                To cancel an order, please contact our customer support team immediately. If your order has already been dispatched, it cannot be canceled.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">2. Refund Policy</h2>
              <p>
                Refunds are applicable in the following cases:
              </p>
              <ul className="list-disc list-inside pl-4">
                <li>If an incorrect product is delivered</li>
                <li>If the product delivered is damaged or expired</li>
                <li>If the delivery is incomplete or missing items</li>
              </ul>
              <p className="mt-4">
                Refunds will be processed within 7-10 business days after the return is approved. Refunds will be credited back to the original payment method used during the transaction.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">3. Returns & Exchange Policy</h2>
              <p>
                In case of any issues with the products delivered, customers can request a return or exchange within 24 hours of receiving the order.
                Please ensure that the products are unused, and all packaging remains intact. Our team will assess the condition of the returned products before processing a refund or exchange.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">4. Non-Refundable Items</h2>
              <p>
                Certain items are non-refundable, including:
              </p>
              <ul className="list-disc list-inside pl-4">
                <li>Perishable items such as fresh fruits, vegetables, and dairy products</li>
                <li>Products that have been opened or used</li>
                <li>Items that are marked as non-refundable on the product description page</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">5. How to Request a Refund or Cancellation</h2>
              <p>
                To request a refund or cancellation, please contact us at <a href="mailto:debdebasmitagrandbazar@gmail.com " className="text-blue-500 underline">debdebasmitagrandbazar@gmail.com </a> 
                or call us at +91 81445 70016. Include your order number and details of the issue to ensure a smooth resolution process.
              </p>
            </section>

          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default RefundCancelation;
