

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import img1 from '../../Assets/Aboutus/dis1.png';
import img2 from '../../Assets/Aboutus/dis2.png';
import img3 from '../../Assets/Aboutus/dis3.png';
import img4 from '../../Assets/Aboutus/dis4.png';
import img5 from '../../Assets/Aboutus/girls.png';
import arrows from '../../Assets/Aboutus/arrows.png';
import arrows2 from '../../Assets/Aboutus/arrows2.png';

const Discount = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    
    return (
        <React.Fragment>
            <div className='flex flex-col lg:flex-row mt-8 px-4 md:px-16 h-[105rem] sm:h-fit'>
                <div className='w-full lg:w-[50%] flex flex-wrap gap-8 justify-center' data-aos="fade-right">
                    <div className='space-y-4'>
                        <h3 className='text-[#2D8014] text-xl md:text-2xl'>PROMOTION DAY</h3>
                        <h1 className='text-3xl md:text-5xl'>Get Discount Up To
                        50% With Our App.</h1>
                        <p className='text-sm md:text-base lg:text-lg'>
                        "Get Discount Up To 50% With Our App" offers exclusive savings, personalized deals, and seamless shopping, encouraging users to enjoy discounts through the mobile app.</p>
                    </div>

                    {/* Cards Section */}
                    <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200' data-aos="zoom-in">
                        <div className='p-6'>
                            <div className='flex mb-4'>
                                <img src={img1} alt='Free Delivery' />
                            </div>
                            <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                                Free Delivery
                            </h2>
                            <p className='text-gray-600 text-center sm:text-start'>
                                We believe in treating our customers with respect. We always thrive to cater to their needs.
                            </p>
                        </div>
                    </div>

                    <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200' data-aos="zoom-in">
                        <div className='p-6'>
                            <div className='flex mb-4'>
                                <img src={img2} alt='Save Time' />
                            </div>
                            <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                                Save Your Time
                            </h2>
                            <p className='text-gray-600 text-center sm:text-start'>
                                We believe in treating our customers with respect. We always thrive to cater to their needs.
                            </p>
                        </div>
                    </div>

                    <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200' data-aos="zoom-in">
                        <div className='p-6'>
                            <div className='flex mb-4'>
                                <img src={img3} alt='Variety Food' />
                            </div>
                            <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                                Variety Food
                            </h2>
                            <p className='text-gray-600 text-center sm:text-start'>
                                We are committed to sustainable and eco-friendly practices.
                            </p>
                        </div>
                    </div>

                    <div className='w-full sm:w-[48%] lg:w-[45%] bg-white rounded-xl shadow-md border border-gray-200' data-aos="zoom-in">
                        <div className='p-6'>
                            <div className='flex mb-4'>
                                <img src={img4} alt='Discount' />
                            </div>
                            <h2 className='text-lg font-semibold text-gray-800 text-center sm:text-start mb-2'>
                                Discount
                            </h2>
                            <p className='text-gray-600 text-center sm:text-start'>
                                We ensure top-notch safety standards in handling chemicals.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w-full lg:w-[50%] relative mt-8 lg:mt-0' data-aos="fade-left">
                    <img src={img5} alt='Girl Image' className='absolute md:right-0 -right-3 top-20 lg:top-44 lg:right-5 w-[75%]' />
                    <img src={arrows} alt='Arrow' className='absolute right-0 -top-10 lg:right-0 md:w-[full] w-[50%]' />
                    <img src={arrows2} alt='Arrow2' className='absolute left-0 -bottom-96 md:bottom-0 lg:left-0 md:w-[full] w-[50%]' />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Discount;
