import React from 'react';

const Blogs = () => {
  return (
    <div>
      Blogs
    </div>
  )
}

export default Blogs
